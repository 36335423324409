import HChart from '@components/HChart/HChart';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import SankeyModule from 'highcharts/modules/sankey';
import type Highcharts from 'highcharts';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import {
  convertToPercentageParsedString,
  formatDecimalSeparator,
} from 'utils/formatters/number/numberUtils';
import { PiArrowDownFill, PiArrowUpFill } from 'react-icons/pi';
import { UnitTypeEnum } from 'enums/UnitTypeEnum';
import { type Sankey } from 'interfaces/sankey/Sankey.interface';
import { type SankeyNodeObject } from 'highcharts';

interface BuildingSankeyChartProps {
  data?: Sankey;
}
interface SankeyPoint {
  fromNode?: {
    category: string;
    name: string;
  };
  toNode?: {
    name: string;
    category: string;
  };
  name?: string;
  weight?: number;
  sum?: number;
}

interface MySankeyNodeObject extends SankeyNodeObject {
  meter_category?: string;
}
const getNodeColor = (color: string, id?: number | string): string => {
  if (id === 'Non-measured consumption') {
    return '#D9D9D9';
  }
  return color === 'green' ? '#8CD134' : '#E92252';
};

const OrganizationSankey: FC<BuildingSankeyChartProps> = ({ data }) => {
  const { theme } = useTheme();
  const [chart, setChart] = useState<Highcharts.Chart | null>(null);

  const [everyNodePositions, setEveryNodePositions] = useState<
    Record<string, { x: number; y: number }>
  >({});

  const unit: UnitTypeEnum =
    UnitTypeEnum[data?.unit_type as keyof typeof UnitTypeEnum];

  const visibleData: any = useMemo(() => {
    if (!data) return [];
    const sankeyData =
      data?.links
        ?.map((link) => ({
          ...link,
          from: link.from_node,
          category: link.meter_category,
        }))
        ?.sort((a, b) => {
          // If a's category is 'Other', push it to the end
          if (a.category === 'Other') return 1;

          // If b's category is 'Other', keep a at its current position
          if (b.category === 'Other') return -1;

          // Otherwise, sort by weight in descending order
          return b.weight - a.weight;
        }) ?? [];

    return sankeyData;
  }, [data]);

  const chartNodes = useMemo(() => {
    if (data?.nodes) {
      const nodes = data.nodes.map((node) => ({
        ...node,
        id: node.meter_id,
        name: node.meter_name,
        color: getNodeColor(node.color, node.id),
        custom: {
          meterId: node.meter_id,
        },
      }));
      return nodes;
    }
    return [];
  }, [data]);

  const chartHeight = useMemo(() => {
    if (visibleData.length > 20) {
      return 800;
    } else if (visibleData.length > 10) {
      return 600;
    }
    return 400;
  }, [visibleData]);

  const options: Highcharts.Options = {
    chart: {
      type: 'sankey',
      height: chartHeight,
      spacingTop: 25,
      spacingBottom: 25,
      spacingLeft: 25,
      spacingRight: 25,
      events: {
        render(this: Highcharts.Chart) {
          setChart(this);
        },
        redraw(this: Highcharts.Chart) {
          setChart(this);
        },
      },
    },
    xAxis: {
      scrollbar: {
        enabled: false,
      },
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    plotOptions: {
      sankey: {
        linkColorMode: 'to',
        minLinkWidth: 2,
        nodeWidth: 35,
        nodeAlignment: 'center',
        nodePadding: 20,
        tooltip: {
          nodeFormatter() {
            return `<b>${
              (this as MySankeyNodeObject)?.meter_category ?? ''
            }:</b></br>
          ${formatDecimalSeparator({
            value: (this as any).sum,
            decimalScale: 2,
          })} ${unit}
            `;
          },
          pointFormatter() {
            const point = this as unknown as SankeyPoint;

            return point?.fromNode && point?.toNode && point?.weight && point
              ? `<b>${point.fromNode.name}</b> → <b>${
                  point.toNode.name
                }</b> <br>
              
                ${formatDecimalSeparator({
                  value: point.weight,
                  decimalScale: 2,
                })} ${unit}`
              : `<b>${point?.name ?? ''}</b>: <br>${point?.sum ?? 0} ${unit}`;
          },
        },
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: theme.colors['gray-25'],
    },

    series: [
      {
        keys: ['from', 'to', 'weight'],
        data: visibleData ?? [],
        type: 'sankey',
        name: 'Flow',
        linkOpacity: 0.25,
        curveFactor: 0.4,
        nodes: (chartNodes as any[]) ?? [],
        dataLabels: {
          align: 'right',
          x: -40,
          useHTML: true,
          nodeFormatter() {
            if ((this.point as any).column === 0) {
              return `<span style="position: relative; left: 40px;">${
                this.key as string
              }</span>`;
            }
            return this.key;
          },
        },
      },
    ],
  };

  useEffect(() => {
    if (!chart) {
      return;
    }

    const updatePositions = (): void => {
      const nodePositions: Record<string, { x: number; y: number }> = {};
      const series = chart?.series?.[0];
      if (!series) return;

      (series as any).nodes.forEach((node: any) => {
        const nodeHeight = node.shapeArgs?.height ?? 1;
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const x = node.nodeX + chart.plotLeft;
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const y = node.nodeY + chart.plotTop + nodeHeight / 2;

        if (x && y) nodePositions[node?.id] = { x, y };
      });

      setEveryNodePositions(nodePositions);
    };
    setTimeout(updatePositions, 0);

    (chart as any)?.addEvent?.('redraw', updatePositions);
    return () => {
      if (chart) {
        (chart as any)?.removeEvent?.('redraw', updatePositions);
      }
    };
  }, [chart, visibleData]);

  const createPorcentageNodes = useCallback(() => {
    return (
      chartNodes?.map((node, index) => {
        if (
          !everyNodePositions ||
          Object.keys(everyNodePositions).length === 0
        ) {
          return <></>;
        }

        const key = `${node?.id}-${node?.sankey_id ?? 0}-${index}`;

        const nodePosition = everyNodePositions?.[node?.id];
        if (!nodePosition?.x || !nodePosition?.y || !node.percentage)
          return <></>;

        return (
          <div
            key={key}
            className="absolute transform -translate-y-1/2 rounded-full p-1 pointer-events-none"
            style={{
              left: `${nodePosition.x - 3}px`,
              top: `${nodePosition.y}px`,
              zIndex: 1,
            }}
          >
            <div className="w-[33px] h-[14px] text-[10px] font-bold bg-white flex items-center rounded-sm justify-evenly">
              <p>
                {convertToPercentageParsedString(Math.abs(node.percentage), {
                  decimalScale: 1,
                  clampPercentage: false,
                })}
              </p>
              {node.color === theme.colors.success ? (
                <PiArrowDownFill fill={node.color} size={14} />
              ) : (
                <PiArrowUpFill fill={node.color} size={14} />
              )}
            </div>
          </div>
        );
      }) ?? <></>
    );
  }, [chartNodes, everyNodePositions]);

  return (
    <div className="relative w-full" id="building-sankey">
      <HChart options={options} modules={[SankeyModule]} callback={setChart} />
      {createPorcentageNodes()}
    </div>
  );
};
export default OrganizationSankey;
