import { CardContainer } from 'layout/Card';
import { type FC } from 'react';
import InformationBox from './InformationBox';
import { useFetchOrganizationSummary } from 'api/organization/organizationServices';

const OrganizationInfoBoxes: FC = () => {
  const { data, isFetching } = useFetchOrganizationSummary();
  return (
    <CardContainer className="justify-between max-w-[350px]">
      <InformationBox
        title="Number of buildings"
        value={data?.metrics?.assets_count}
        isFetching={isFetching}
      />
      <InformationBox
        title="Total surface"
        value={data?.metrics?.total_gross_area}
        unit=" m2"
        isFetching={isFetching}
      />
      <InformationBox
        title="Renewable energy"
        value={data?.renewable_energy_target}
        isFetching={isFetching}
        unit="%"
      />
      <InformationBox
        title="Organizational savings target"
        value={data?.savings_target}
        unit="%"
        isFetching={isFetching}
      />
    </CardContainer>
  );
};
export default OrganizationInfoBoxes;
