import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import {
  getUtilityIcon,
  getUtilityTypeReadableName,
} from 'utils/utilityTypeUtils/utilityTypeUtils';
import Tippy from '@tippyjs/react';
import { StatusChip } from '@components/statusChip';
import { numericFormatter } from 'react-number-format';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';
import { getDaysFromStatusLastUpdate } from 'utils/formatters/getDaysFromDate/getDaysFromDate';

interface AnomaliesTableColumnsReturnType {
  columns: ColumnDef<any, any>[];
}

const useGetOrganizationOperationsTableColumns =
  (): AnomaliesTableColumnsReturnType => {
    const columnHelper = createColumnHelper<any>();

    const columns = [
      columnHelper.accessor('name', {
        header: () => 'Meter name',
        cell: (info) => {
          const values = info.row.original;
          return (
            <Tippy
              content={
                <div className="p-2">
                  <p>{getUtilityTypeReadableName(values.utility_type)}</p>
                  <p>
                    Moment of change:{' '}
                    {convertToReadableDate(values.moment_change)}
                  </p>
                  <p>
                    Time on status:{' '}
                    {getDaysFromStatusLastUpdate(values.time_on_status)}
                  </p>
                </div>
              }
              maxWidth={450}
            >
              <span className="flex items-center gap-2.5">
                <div className="fill-gray-50">
                  {getUtilityIcon(values.utility_type)}
                </div>
                <span className="max-w-[180px] desktop-l:max-w-[200px] block text-ellipsis overflow-hidden truncate">
                  {getUtilityTypeReadableName(values.utility_type)}
                </span>
              </span>
            </Tippy>
          );
        },
        enableSorting: true,
        size: 200,
        maxSize: 200,
      }),
      columnHelper.accessor('asset_name', {
        header: () => 'Building',
        cell: (info) => {
          return (
            <Tippy content={info.getValue()} maxWidth={450}>
              <span className="flex items-center gap-2.5">
                <span className="block text-ellipsis overflow-hidden truncate">
                  {info.renderValue()}
                </span>
              </span>
            </Tippy>
          );
        },
        enableSorting: true,
        size: 100,
        maxSize: 200,
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: (info) => <StatusChip status={info.getValue()} />,
        enableSorting: true,
        size: 100,
        maxSize: 200,
      }),
      columnHelper.accessor('impact_average_diff_euro', {
        header: () => 'Impact per day',
        cell: (info) =>
          `${numericFormatter((info.getValue() ?? 0).toFixed(2), {
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 2,
          })} €`,
        enableSorting: true,
        size: 100,
        maxSize: 200,
      }),
    ];

    return {
      columns,
    };
  };
export default useGetOrganizationOperationsTableColumns;
