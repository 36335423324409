import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationBuildingsWithTagsComponent from './components/OrganizationBuildingsWithTagsComponent/OrganizationBuildingsWithTagsComponent';
import OrganizationMapAndInfo from './components/OrganizationMapAndInfo/OrganizationMapAndInfo';
import { OrganizationOperationsAndProjects } from './components/OrganizationOperationsAndProjects';
import { OrganizationEnergyOverview } from './components/OrganizationEnergyOverview';

const OrganizationViewPage: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4">
      <div className="mb-8">
        <h1 className="mb-2">My organization</h1>
        <h3 className="text-base">{t('dashboardPage.subTitle')}</h3>
      </div>

      <OrganizationMapAndInfo />
      <OrganizationBuildingsWithTagsComponent />
      <OrganizationOperationsAndProjects />
      <OrganizationEnergyOverview />
    </div>
  );
};
export default OrganizationViewPage;
