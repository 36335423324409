import { type MapRef, Marker } from 'react-map-gl';

import { type FC, type MutableRefObject, useMemo, useState } from 'react';
import MapMarkerIcon from '@components/icons/MapIcons/MapMarkerIcon';
import { createPortal } from 'react-dom';
import NeedAttentionTag from './NeedsAttention';
import { type AssetTags } from 'interfaces/assets/Asset.interface';

interface CustomMarkerWithTooltipProps {
  longitude: number;
  latitude: number;
  name: string;
  tags?: AssetTags[];
  tooltipContent?: string;
  mapRef: MutableRefObject<MapRef | null>;
  mapContainerRef: MutableRefObject<null>;
}

const CustomMarkerWithTooltip: FC<CustomMarkerWithTooltipProps> = ({
  longitude,
  latitude,
  name,
  tags,
  mapRef,
  mapContainerRef,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const markerPosition = useMemo<{ x?: number; y?: number } | undefined>(() => {
    if (!mapRef?.current) {
      return;
    }
    return mapRef?.current?.project([longitude, latitude]);
  }, [mapRef?.current, longitude, latitude, showTooltip]);
  const needsAttention = tags && tags?.length > 0;

  return (
    <Marker longitude={longitude} latitude={latitude} anchor="bottom">
      <div
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        style={{ cursor: 'pointer' }}
      >
        <MapMarkerIcon fill={needsAttention ? '#E92252' : '#8CD134'} />

        {/* Tooltip that appears on hover */}
        {showTooltip &&
          mapContainerRef?.current &&
          createPortal(
            <div
              className="flex flex-col gap-2"
              style={{
                position: 'absolute',
                top: `${markerPosition?.y ?? 0}px`,
                left: `${markerPosition?.x ?? 0}px`,
                transform: 'translateX(20%)',
                backgroundColor: 'white',
                padding: '10px',
                borderRadius: '4px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                zIndex: 9999,
                minWidth: '300px',
                pointerEvents: 'none',
              }}
            >
              <p className="font-bold text-xs text-gray-60">{name}</p>
              <NeedAttentionTag needsAttention={needsAttention} size="small" />
              {needsAttention && (
                <div className="flex flex-col gap-2 bg-gray-10 px-2 py-1 rounded-lg text-xs">
                  <p className="text-gray-60">Attention needed:</p>
                  <ul className="flex flex-col gap-2 list-disc pl-3">
                    {tags?.map((tag) => <li key={tag.tag}>{tag.tag}</li>)}
                  </ul>
                </div>
              )}
            </div>,
            mapContainerRef.current
          )}
      </div>
    </Marker>
  );
};

export default CustomMarkerWithTooltip;
