import { CardContainer } from 'layout/Card';
import CardContainerTitleSeeAll from 'layout/Card/CardContainer/CardContainerTitleSeeAll';
import OrganizationBuildingsWithTagsTable from 'pages/OrganizationViewPage/tables/OrganizationBuildingsAttention/OrganizationBuildingsWithTagsTable';
import { type FC } from 'react';

const OrganizationBuildingsWithTagsComponent: FC = () => {
  return (
    <div>
      <CardContainerTitleSeeAll
        title="BUILDINGS THAT NEED ATTENTION"
        redirect="/app/assets/list#assets"
      />
      <CardContainer>
        <OrganizationBuildingsWithTagsTable />
      </CardContainer>
    </div>
  );
};
export default OrganizationBuildingsWithTagsComponent;
