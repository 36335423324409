import { type FC, type SVGProps } from 'react';

const MapMarkerIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="35"
    viewBox="0 0 32 35"
    fill="none"
    {...props}
  >
    <g filter="url(#filter0_d_6334_13394)">
      <path
        d="M27.4294 13.7098C27.4294 22.3074 17.7412 29.7098 16.0008 29.7098C14.2604 29.7098 4.57227 22.3074 4.57227 13.7098C4.57227 7.398 9.68901 2.28125 16.0008 2.28125C22.3127 2.28125 27.4294 7.398 27.4294 13.7098Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0006 10.2868C14.107 10.2868 12.572 11.8219 12.572 13.7154C12.572 15.6089 14.107 17.144 16.0006 17.144C17.8941 17.144 19.4291 15.6089 19.4291 13.7154C19.4291 11.8219 17.8941 10.2868 16.0006 10.2868ZM5.71484 13.7154C5.71484 8.03476 10.3199 3.42969 16.0006 3.42969C21.6812 3.42969 26.2863 8.03476 26.2863 13.7154C26.2863 19.396 21.6812 24.0011 16.0006 24.0011C10.3199 24.0011 5.71484 19.396 5.71484 13.7154Z"
      fill="current"
    />
    <defs>
      <filter
        id="filter0_d_6334_13394"
        x="2.28655"
        y="2.28125"
        width="27.4289"
        height="32.0011"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.28571" />
        <feGaussianBlur stdDeviation="1.14286" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6334_13394"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6334_13394"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default MapMarkerIcon;
