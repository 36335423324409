import { IconTable } from '@components/table/IconTable';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC, useState } from 'react';
import useGetOrganizationBuildingsAttentionColumns from './hook/useGetOrganizationBuildingsAttention';
import { useNavigate } from 'react-router-dom';
import { type AssetWithTagsResponse } from 'interfaces/assets/Asset.interface';

import { useFetchAssetsWithTags } from 'api/organization/organizationServices';

const OrganizationBuildingsWithTagsTable: FC = () => {
  const [hoveredId, setHoveredId] = useState<undefined | number>(undefined);
  const { columns } = useGetOrganizationBuildingsAttentionColumns();
  const { data, isFetching } = useFetchAssetsWithTags();
  const { theme } = useTheme();
  const navigate = useNavigate();

  const navigateToAsset = (asset: AssetWithTagsResponse): void => {
    if (asset) navigate(`/app/assets/building/details/${asset.id}`);
  };

  return (
    <div className="rounded-lg ">
      <IconTable<AssetWithTagsResponse>
        minHeight={280}
        maxHeight={280}
        skeletonAmountWhenLoading={3}
        isFetching={isFetching}
        enableRowBorder={false}
        stripped={false}
        onRowHover={(id) => {
          setHoveredId(id);
        }}
        onClickRow={navigateToAsset}
        hoveredId={hoveredId}
        hoverColor={theme.colors['tertiary-lighter']}
        enableRowRoundedBorder={true}
        data={data ?? []}
        columns={columns}
        emptyTableMessage="No underperforming buildings"
      />
    </div>
  );
};
export default OrganizationBuildingsWithTagsTable;
