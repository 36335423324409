// import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC } from 'react';
interface TableTagProps {
  name: string;
}

const TableTag: FC<TableTagProps> = ({ name }) => {
  //   const { theme } = useTheme();
  return (
    <div className="text-xs px-2.5 py-1 rounded-xl bg-primary-lighter text-primary font-bold w-fit">
      <p>{name}</p>
    </div>
  );
};
export default TableTag;
