import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import clsx from 'clsx';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { formatDecimalSeparator } from 'utils/formatters/number/numberUtils';

const InformationBox: FC<{
  title: string;
  value?: number;
  unit?: string;
  isFetching?: boolean;
  tooltipContent?: string;
  decimal?: number;
}> = ({ title, value, unit, isFetching, tooltipContent, decimal = 0 }) => {
  const { theme } = useTheme();

  return (
    <div
      className={clsx(
        'flex flex-col gap-2 px-4 py-2 bg-gray-10 rounded-lg border ',
        'border-gray-10'
      )}
    >
      {isFetching ? (
        <Skeleton height={24} containerClassName="flex-1" />
      ) : (
        <div className="flex justify-between items-center">
          <p className="text-base">{title}</p>
          {tooltipContent && (
            <TooltipIconComponent
              content={tooltipContent}
              color={theme.colors['gray-45']}
            />
          )}
        </div>
      )}

      <div className="flex justify-between items-center">
        {isFetching ? (
          <Skeleton height={30} containerClassName="flex-1" />
        ) : (
          <p
            className="text-2xl font-bold leading-[39px]"
            style={{ fontSize: 28 }}
          >
            {value !== undefined
              ? formatDecimalSeparator({
                  value,
                  decimalScale: decimal,
                })
              : '-'}
            {unit ?? ''}
          </p>
        )}
      </div>
    </div>
  );
};

export default InformationBox;
