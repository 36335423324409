import { IconTable } from '@components/table/IconTable';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchDashboardList } from 'api/dashboard/dashboardServices';
import useGetOrganizationOperationsTableColumns from './hook/useGetOrganizationOperationsTableColumns';
import { type Change } from 'interfaces/change/ChangeData.interface';

const OrganizationOperationsTable: FC = () => {
  const [hoveredId, setHoveredId] = useState<undefined | number>(undefined);
  const { columns } = useGetOrganizationOperationsTableColumns();
  const { data, isFetching } = useFetchDashboardList();
  const { theme } = useTheme();
  const navigate = useNavigate();

  const navigateToChange = (change: Change): void => {
    if (change) navigate(`/app/operations/anomalies/details/${change.id}`);
  };

  return (
    <div className="rounded-lg">
      <IconTable<Change>
        minHeight={260}
        skeletonAmountWhenLoading={3}
        isFetching={isFetching}
        enableRowBorder={false}
        stripped={false}
        onRowHover={(id) => {
          setHoveredId(id);
        }}
        onClickRow={navigateToChange}
        hoveredId={hoveredId}
        hoverColor={theme.colors['tertiary-lighter']}
        enableRowRoundedBorder={true}
        data={data?.change_detections ?? []}
        columns={columns}
        emptyTableMessage="No underperforming buildings"
      />
    </div>
  );
};
export default OrganizationOperationsTable;
