import { HappyFaceIcon, SadFaceIcon } from '@components/icons/FaceIcon';
import clsx from 'clsx';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC, useMemo } from 'react';

interface NeedsAttentionTagProps {
  needsAttention?: boolean;
  size: 'small' | 'large';
}

const NeedAttentionTag: FC<NeedsAttentionTagProps> = ({
  needsAttention,
  size = 'small',
}) => {
  const { theme } = useTheme();

  const getSizeClassNames = (): string => {
    if (size === 'small') {
      return 'py-0.5 px-1 gap-1 text-xs';
    } else if (size === 'large') {
      return 'px-4 py-2.5 gap-4 text-lg';
    }
    return '';
  };

  const getIconSize = (): { width: string; height: string } | undefined => {
    if (size === 'small') {
      return { width: '16px', height: '16px' };
    } else if (size === 'large') {
      return { width: '20px', height: '20px' };
    }
  };

  const attentionSpecificLayout = useMemo(() => {
    return needsAttention
      ? {
          fontColor: theme.colors.error,
          bgColor: theme.colors['status-bg'].error,
          text: 'Attention needed',
          icon: <SadFaceIcon {...getIconSize()} />,
        }
      : {
          fontColor: theme.colors.status.solved,
          bgColor: '#23B44C1A',
          text: 'No action required',
          icon: <HappyFaceIcon {...getIconSize()} />,
          tooltip: undefined,
        };
  }, [needsAttention]);

  return needsAttention !== undefined ? (
    <div
      className={clsx(
        'flex justify-between items-center w-fit rounded-lg',
        getSizeClassNames()
      )}
      style={{ backgroundColor: attentionSpecificLayout?.bgColor }}
    >
      {attentionSpecificLayout?.icon}
      <p style={{ color: attentionSpecificLayout?.fontColor }}>
        {attentionSpecificLayout?.text}
      </p>
    </div>
  ) : (
    <></>
  );
};
export default NeedAttentionTag;
