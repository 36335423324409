import { UnitTypeEnum } from 'enums/UnitTypeEnum';
import { UtilityTypeEnum } from 'enums/UtilityTypeEnum';
import { type SankeyUtilityTypes } from 'interfaces/sankey/Sankey.interface';
import { BuildingSankeyButton } from 'pages/BuildingViewPage/components/BuildingSankeyButton';
import { type FC } from 'react';

interface OrganizationSankeyButtonsProps {
  utilityTypes?: SankeyUtilityTypes[];
  selectedUtilityType: UtilityTypeEnum;
  handleSelection: (utilityType: string) => void;
}

const OrganizationSankeyButtons: FC<OrganizationSankeyButtonsProps> = ({
  utilityTypes,
  selectedUtilityType,
  handleSelection,
}) => {
  return (
    <div className="flex gap-2">
      {utilityTypes?.map((btn) => {
        const utilityType = btn.type.toUpperCase();
        return (
          <BuildingSankeyButton
            key={utilityType}
            id={utilityType}
            isSelected={selectedUtilityType === utilityType}
            handleSelection={handleSelection}
            utilityType={
              UtilityTypeEnum[utilityType as keyof typeof UtilityTypeEnum]
            }
            value={btn.energy_use ?? 0}
            percentage={btn.percentage ?? 0}
            unit={
              UnitTypeEnum[btn.unit_type as keyof typeof UnitTypeEnum] ?? ''
              //   chartUnitType?.[parsedUtility as keyof typeof chartUnitType] ?? ''
            }
          />
        );
      })}
    </div>
  );
};
export default OrganizationSankeyButtons;
