import { IconTable } from '@components/table/IconTable';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC, useState } from 'react';
import useGetOrganizationProjectsTableColumns from './hook/useGetOrganizationProjectsTableColumns';
import { useNavigate } from 'react-router-dom';
import { type OrganizationProject } from 'interfaces/organization/Organization.interface';
import { useListProjectsThatNeedsAttention } from 'api/projects/projectsServices';

const OrganizationProjectsTable: FC = () => {
  const [hoveredId, setHoveredId] = useState<undefined | number>(undefined);
  const { columns } = useGetOrganizationProjectsTableColumns();
  const { data, isFetching } = useListProjectsThatNeedsAttention();
  const { theme } = useTheme();
  const navigate = useNavigate();

  const navigateToProject = (project: OrganizationProject): void => {
    if (project) navigate(`/app/management/projects/details/${project.id}`);
  };

  return (
    <div className="rounded-lg">
      <IconTable<OrganizationProject>
        minHeight={260}
        skeletonAmountWhenLoading={3}
        isFetching={isFetching}
        enableRowBorder={false}
        stripped={false}
        onRowHover={(id) => {
          setHoveredId(id);
        }}
        onClickRow={navigateToProject}
        hoveredId={hoveredId}
        hoverColor={theme.colors['tertiary-lighter']}
        enableRowRoundedBorder={true}
        data={data?.projects ?? []}
        columns={columns}
        emptyTableMessage="No underperforming projects"
      />
    </div>
  );
};
export default OrganizationProjectsTable;
