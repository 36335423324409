import { type FC, useState } from 'react';
import { OrganizationSankey } from '../OrganizationSankey';
import { useFetchOrganizationSankey } from 'api/organization/organizationServices';
import OrganizationSankeyButtons from './OrganizationSankeyButtons';
import { UtilityTypeEnum } from 'enums/UtilityTypeEnum';
import { CardContainer, CardTitle } from 'layout/Card';

const OrganizationEnergyOverview: FC = () => {
  const [selectedUtilityType, setSelectedUtilityType] = useState(
    UtilityTypeEnum.ELECTRICITY
  );
  const { data } = useFetchOrganizationSankey({
    utilityType: selectedUtilityType,
  });

  const handleSelection = (utilityType: string): void => {
    const enumKey = Object.keys(UtilityTypeEnum).find(
      (key) =>
        UtilityTypeEnum[key as keyof typeof UtilityTypeEnum] === utilityType
    );

    if (enumKey) {
      setSelectedUtilityType(
        UtilityTypeEnum[enumKey as keyof typeof UtilityTypeEnum]
      );
    }
  };

  return (
    <>
      <CardTitle title="energy overview" />
      <CardContainer>
        <h4 className="font-bold text-gray-60">Consumption evolution</h4>
        <div className="my-6">
          <OrganizationSankeyButtons
            utilityTypes={data?.utility_types}
            selectedUtilityType={selectedUtilityType}
            handleSelection={handleSelection}
          />
        </div>

        <OrganizationSankey data={data?.sankey} />
      </CardContainer>
    </>
  );
};
export default OrganizationEnergyOverview;
