import { login } from 'api/auth/authService';
import { PasswordField } from 'components/auth/PasswordField';
import { FTextField } from 'components/form/FTextField';
import { EmailIcon } from 'components/icons/EmailIcon';
import useHRForm from 'hooks/useHRForm';
import { Button } from 'layout/Button';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { LoginForminitialValues } from './initialValues';
import { type LoginSchemaType, loginControlNames, loginSchema } from './schema';
import { useAuth } from 'hooks/contexts/AuthContext';

const LoginForm: FC = () => {
  const { setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { Form } = useHRForm({
    schema: loginSchema,
    initialValues: LoginForminitialValues,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (data: LoginSchemaType): Promise<void> => {
    setIsLoading(true);
    const response = await login(data);
    setIsLoading(false);

    if (response?.status !== 200) {
      return;
    }
    if (response?.data?.should_change_password) {
      const resetToken = localStorage.getItem('jwtToken');
      if (resetToken) navigate(`/reset-password?token=${resetToken}`);
      return;
    }
    setUser({
      id: response.data.user.id,
      company_id: response.data.user.company_id,
      name: response.data.user.given_name,
      family_name: response.data.user.family_name,
      email: response.data.user.email,
      company_name: response.data.company_name,
      role: response.data.user.role,
    });
    navigate('/app/dashboard');
  };

  return (
    <div data-testid="login-form">
      <Form onSubmit={onSubmit}>
        <div className="flex flex-col gap-6">
          <FTextField
            name={loginControlNames.email}
            label={t('common.inputs.email')}
            placeholder={t('common.placeholders.email')}
            addorment={{ endAddorment: <EmailIcon /> }}
            data-testid="email-input"
          />
          <div className="flex flex-col gap-2">
            <PasswordField
              name={loginControlNames.password}
              label={t('common.inputs.password')}
              placeholder={t('common.placeholders.enterPassword')}
              data-testid="password-input"
            />
            <a href="/forgot-password" className="font-bold">
              {t('loginPage.forgotYourPassword')}
            </a>
          </div>
        </div>

        <Button
          className="w-full mt-lg"
          type="submit"
          label={t('common.login')}
          size="large"
          isLoading={isLoading}
        />

        <p className="mt-xl text-lg text-center text-gray-50">
          {t('loginPage.dontHaveAnAccount')}{' '}
          <Link to="/register" className="font-bold">
            {t('loginPage.signup')}
          </Link>
        </p>
      </Form>
    </div>
  );
};

export default LoginForm;
