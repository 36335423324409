import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import axiosInstance from 'api/axios';
import { type AssetWithTagsResponse } from 'interfaces/assets/Asset.interface';
import {
  type MapFunctionsResponse,
  type OrganizationProjectsResponse,
  type OrganizationSankeyResponse,
  type OrganizationSummaryResponse,
} from 'interfaces/organization/Organization.interface';

export const useFetchOrganizationSankey = ({
  utilityType,
}: {
  utilityType?: string;
}): UseQueryResult<Partial<OrganizationSankeyResponse>> => {
  return useQuery<Partial<OrganizationSankeyResponse>>({
    queryKey: ['organizationSankey', utilityType],
    queryFn: async () => {
      if (!utilityType) return;
      const response = await axiosInstance.get(`organization_summary/sankey`, {
        params: { utility_type: utilityType },
      });
      return response.data;
    },
    placeholderData: (prev) => {
      return { utility_types: prev?.utility_types };
    },
  });
};

export const useFetchOrganizationMap = (): UseQueryResult<
  MapFunctionsResponse[]
> => {
  return useQuery<MapFunctionsResponse[]>({
    queryKey: ['organizationMap'],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `organization_summary/map_functions`
      );
      return response.data;
    },
  });
};

export const useFetchOrganizationSummary =
  (): UseQueryResult<OrganizationSummaryResponse> => {
    return useQuery<OrganizationSummaryResponse>({
      queryKey: ['organizationSummary'],
      queryFn: async () => {
        const response = await axiosInstance.get(`organization_summary/`);
        return response.data;
      },
    });
  };

export const useFetchOrganizationProjects =
  (): UseQueryResult<OrganizationProjectsResponse> => {
    return useQuery<OrganizationProjectsResponse>({
      queryKey: ['organizationProjects'],
      queryFn: async () => {
        const response = await axiosInstance.get(
          `organization_summary/projects_needs_attention`
        );
        return response.data;
      },
    });
  };
export const useFetchAssetsWithTags = (): UseQueryResult<
  AssetWithTagsResponse[]
> => {
  return useQuery<AssetWithTagsResponse[]>({
    queryKey: ['organizationAssetsWithTags'],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `organization_summary/assets_with_tags`
      );
      return response.data;
    },
  });
};
