import MapContainer, { MAP_STYLES } from '@components/map/Map';
import { type FC, useMemo, useRef } from 'react';
import { type MapRef } from 'react-map-gl';
import Skeleton from 'react-loading-skeleton';
import { CardContainer } from 'layout/Card';
import { SingleSelectInput } from '@components/inputs/SelectInput/SingleSelectInput';
import CustomMarkerWithTooltip from './CustomMarkerWithTooltip';
import { useFetchOrganizationMap } from 'api/organization/organizationServices';

const OrganizationViewMap: FC = () => {
  const mapRef = useRef<MapRef | null>(null);
  const mapContainerRef = useRef(null);
  const { data, isFetching } = useFetchOrganizationMap();

  const coordinates = useMemo(() => {
    if (data && data?.length > 0) {
      return data.map((building) => [building.longitude, building.latitude]);
    }
    return [];
  }, [data]);

  return (
    <CardContainer>
      <div className="flex flex-col flex-1 gap-3">
        <div className="flex items-center justify-end">
          <SingleSelectInput
            options={[]}
            maxWidth="250px"
            placeholder="Showing all groups"
            disabled={true}
          />
        </div>
        {isFetching ? (
          <Skeleton height={350} containerClassName="flex-1" />
        ) : (
          <div
            ref={mapContainerRef}
            className="relative"
            style={{ height: 350 }}
          >
            <MapContainer
              ref={mapRef}
              coordinates={coordinates}
              height={350}
              mapStyle={MAP_STYLES.streets}
              fitBounds={true}
            >
              {data?.map((building) => {
                return (
                  <CustomMarkerWithTooltip
                    key={building.name}
                    longitude={building.longitude}
                    latitude={building.latitude}
                    name={building.name}
                    tags={building?.tags}
                    mapRef={mapRef}
                    mapContainerRef={mapContainerRef}
                  />
                );
              })}
            </MapContainer>
          </div>
        )}
      </div>
    </CardContainer>
  );
};

export default OrganizationViewMap;
