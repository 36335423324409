import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import Tippy from '@tippyjs/react';
import TableTag from '../TableTag';
import { type AssetWithTagsResponse } from 'interfaces/assets/Asset.interface';

interface AnomaliesTableColumnsReturnType {
  columns: ColumnDef<any, any>[];
}

const useGetOrganizationBuildingsAttentionColumns =
  (): AnomaliesTableColumnsReturnType => {
    const columnHelper = createColumnHelper<AssetWithTagsResponse>();

    const columns = [
      columnHelper.accessor('name', {
        header: () => 'Building',
        cell: (info) => {
          return (
            <Tippy content={info.getValue()} maxWidth={450}>
              <span className="flex items-center gap-2.5">
                <span className="block text-ellipsis overflow-hidden truncate">
                  {info.renderValue()}
                </span>
              </span>
            </Tippy>
          );
        },
        enableSorting: true,
        size: 150,
        maxSize: 200,
      }),
      columnHelper.accessor('location', {
        header: () => 'location',
        cell: (info) => {
          return (
            <Tippy content={info.getValue()} maxWidth={450}>
              <span className="flex items-center gap-2.5">
                <span className="block text-ellipsis overflow-hidden truncate">
                  {info.renderValue()}
                </span>
              </span>
            </Tippy>
          );
        },
        enableSorting: true,
        size: 100,
        maxSize: 100,
      }),
      columnHelper.accessor('function', {
        header: () => 'Function',
        cell: (info) => {
          return (
            <Tippy content={info.getValue()} maxWidth={450}>
              <span className="flex items-center gap-2.5">
                <span className=" block text-ellipsis overflow-hidden truncate">
                  {info.renderValue()}
                </span>
              </span>
            </Tippy>
          );
        },
        enableSorting: true,
        size: 100,
        maxSize: 100,
      }),
      columnHelper.accessor('tags', {
        header: () => 'Attention needed',
        cell: (info) => {
          const values = info.row.original;
          const tags = values.tags;
          return (
            <div className="flex w-full min-w-[300px] max-w-[600px] flex-wrap gap-1">
              {tags?.map((tag, index) => {
                return <TableTag key={`${tag.tag}${index}`} name={tag.tag} />;
              })}
            </div>
          );
        },
        enableSorting: true,
        size: 450,
        maxSize: 600,
      }),
    ];

    return {
      columns,
    };
  };
export default useGetOrganizationBuildingsAttentionColumns;
