import { type FC } from 'react';
import { Link } from 'react-router-dom';

interface CardContainerTitleSeeAllProps {
  title: string;
  redirect?: string;
}

const CardContainerTitleSeeAll: FC<CardContainerTitleSeeAllProps> = ({
  title,
  redirect,
}) => {
  return (
    <div className="flex w-full justify-between items-center mt-2 mb-4 text-gray-60">
      <h3 className="font-bold uppercase">{title}</h3>
      <Link to={redirect ?? '#'} className="text-base mr-6 text-tertiary-dark">
        See all
      </Link>
    </div>
  );
};
export default CardContainerTitleSeeAll;
