import { type FC } from 'react';
import { OrganizationInfoBoxes } from '../OrganizationInfoBoxes';
import { OrganizationViewMap } from '../OrganizationViewMap';

const OrganizationMapAndInfo: FC = () => {
  return (
    <div className="flex gap-4">
      <OrganizationViewMap />
      <OrganizationInfoBoxes />
    </div>
  );
};
export default OrganizationMapAndInfo;
