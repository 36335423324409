import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { CardContainer } from 'layout/Card';
import CardContainerTitleSeeAll from 'layout/Card/CardContainer/CardContainerTitleSeeAll';
import { OrganizationOperationsTable } from 'pages/OrganizationViewPage/tables/OrganizationOperationsTable';
import OrganizationProjectsTable from 'pages/OrganizationViewPage/tables/OrganizationProjectsTable/OrganizationProjectsTable';
import { type FC } from 'react';

const OrganizationOperationsAndProjects: FC = () => {
  return (
    <div className="flex gap-4">
      <div className="flex-1 w-1/2">
        <CardContainerTitleSeeAll
          title="Operations"
          redirect="/app/operations/anomalies"
        />
        <CardContainer>
          <div className="flex gap-4 items-center text-gray-60 my-2">
            <h3 className="bold text-base">Most important</h3>
            <TooltipIconComponent content="TBD" />
          </div>
          <OrganizationOperationsTable />
        </CardContainer>
      </div>
      <div className="flex-1 w-1/2">
        <CardContainerTitleSeeAll
          title="Projects"
          redirect="/app/management/projects"
        />
        <CardContainer>
          <div className="flex gap-4 items-center text-gray-60 my-2">
            <h3 className="bold text-base">Need attention</h3>
            <TooltipIconComponent content="TBD" />
          </div>
          <OrganizationProjectsTable />
        </CardContainer>
      </div>
    </div>
  );
};
export default OrganizationOperationsAndProjects;
